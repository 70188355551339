:root {
    --black: black;
    --blue: #0748ae;
    --white: #c8d1d9;
}

.og-examples__panel {
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    flex-direction: row;
}

.mb-3 {
    border: none !important;
}

.nav-item button {
    border-radius: 5px !important;
    border: none !important;
}

.mb-3 input {
    background-color: #c8d1d9 !important;
}

.btn.btn-primary {
    background-color: var(--blue) !important;
    color: var(--white) !important;
    border: none !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: var(--blue) !important;
    color: var(--white) !important;
}

.nav-link {
    color: var(--white) !important;
}